/*
 * File: 404.js
 * Project: our-wave-stories-app
 *
 * Created by Brendan Michaelsen on December 30, 2021 at 4:28 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: March 23, 2024 at 6:42 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Requires
 */

// Babel
require('core-js/stable');
require('regenerator-runtime/runtime');

// Modules
require('../modules/general');
require('../modules/navigation');
